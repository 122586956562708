import React from "react";
import s from "./server.module.scss";
const urlApi = "https://cawebapi7.mizerov.net/api/Pushes";

const Server = () => {
    const [a, setA] = React.useState(0);
    const curDateTime = () => {
        let d = new Date();
        let da = d.getDate();
        let mo = d.getMonth() + 1;
        let ye = d.getFullYear();
        let ho = d.getHours();
        let mi = d.getMinutes();
        let se = d.getSeconds();
        return `${da}.${mo}.${ye} ${(ho<10?"0":"")+ho}:${(mi<10?"0":"")+mi}:${(se<10?"0":"")+se}`;
    }
    const LoadData = () => {
        fetch(urlApi)
            .then((res) => res.json())
            .then((res) => 
            {
                let n = res;
                var srv = document.querySelector("#srv");
                var str = n + " - " + curDateTime();
                //console.log(str);
                srv.innerHTML = str;  
                setA(n/255);
            });
    }
    LoadData();
    setInterval(LoadData, 1000);

    return (
        <div id="srv" 
            className={s.server} 
            style={{backgroundColor: "rgba(255,10,10,"+a+")"}}>
            <h1>Server status</h1>
        </div>
    );
} 

export default Server;