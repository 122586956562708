//import React, { useEffect } from 'react';
import s from './alert.module.css';

function dateTime(str){
    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(str).toLocaleDateString([], options);
}
const Alert = (props) => {
    const a = props.alert;
    const e = a.exchange;
    const t = a.type;

    let ex = "/img/binance.png";
    if(e === 2) ex = "/img/kucoin.png"
    if(e === 3) ex = "/img/huobi.png"
    let ty = "/img/up.png";
    if(t === 2) ty = "/img/down.png"
    if(t === 3) ty = "/img/both.png"
    return (
        <div className={s.alert}>
            <div><img src={ex} width="23px" alt="" /></div>
            <div className={s.symbol}>
                <b>{a.symbol}</b>
            </div>
            <div>{dateTime(a.dtc)}</div>
            <div>{a.procent}%</div>
            <div><img src={ty} width="23px" alt="" /></div>
        </div> 
    );
}


export default Alert;