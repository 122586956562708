import { Component } from "react";
import Alert from "../alert/alert";
import s from './alerts.module.css';

const urlApiBase = "https://cawebapi7.mizerov.net/api/Alerts?uuid=";

class Alerts extends Component {
  constructor() {
    super();
    this.state = {
      uuid: "",
      error: null,
      IsLoaded: false,
      alerts: []
    }
  }
  LoadData(uuid) {
    fetch(urlApiBase + uuid)
      .then((res) => res.json())
      .then(
        (okJson) => this.setState({uuid: uuid, alerts: okJson, IsLoaded: true}),
        (error) => this.setState({IsLoaded: true, error})
      );  
  }
  componentDidMount() {
    //setInterval(this.LoadData, 3000)
    this.LoadData(this.props.uuid);
  }
  render() {

    let { error, IsLoaded, alerts } = this.state;

    if(this.props.uuid !== this.state.uuid) {
      IsLoaded = false;
      this.LoadData(this.props.uuid);
    }

    if(error) 
      return <div className="Error">Error: {error.message}</div>;
    if(IsLoaded)
      return (
        <div className={s.alerts}>
        {
            alerts.map(a => 
              <Alert key={a.id} alert={a} />
            )
        }
        </div>
      );
      else
        return <div className="Loading">Loading...</div>
  }
}

export default Alerts;