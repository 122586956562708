import { Component } from 'react';
import './App.scss';
import Alerts from './components/alerts/alerts';
import Server from './components/server/server';

class App extends Component {
  state = {
    uuid: "63C0AD1C-0B75-4B96-BEF1-533E3C8B10F2"
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="App-logo">
            <img src="/img/logo.png" alt="" />
            <h1>Crypto Alerts</h1>
          </div>
          <div className="UuidSelector">
            <div className="IsActive" onClick={this.loadAl}>Alexandr</div>
            <div onClick={this.loadAn}>Andrey</div>
            <div onClick={this.reload}>Reload</div>
          </div>
        </header>
        <div className="App-body">
          <Alerts uuid={this.state.uuid} />
          <Server />
        </div>
      </div>
    );
  }
  reload = () => {
    this.forceUpdate();
  }
  loadAl = (e) => {
    this.setState({uuid: "63C0AD1C-0B75-4B96-BEF1-533E3C8B10F2"});
    document.querySelector(".IsActive").classList.remove("IsActive");
    e.target.classList.add("IsActive");
  }
  loadAn = (e) => {
    this.setState({uuid: "79A4C953-3EB3-47B6-A37C-A7C0BCD99C6C"});
    document.querySelector(".IsActive").classList.remove("IsActive");
    e.target.classList.add("IsActive");
  }
}

export default App;
